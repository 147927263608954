

import step1 from "../pics/how-to/step1.png";
import step2 from "../pics/how-to/step2.png";
import step3 from "../pics/how-to/step3.png";
import step4 from "../pics/how-to/step4.png";
import step5 from "../pics/how-to/step5.png";
import step6 from "../pics/how-to/step6.png";
import step7 from "../pics/how-to/step7.png"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";


export function HowtoUseDialog () {

    const [howToUseOpen, setHowToUseOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    function PaperComponent(props) {
        return (
            <Draggable>
                <Paper {...props} />
            </Draggable>
        )
    }

    const handleHowToUseOpen = (scrollType) => () => {
        setHowToUseOpen(true);
        setScroll(scrollType)
    }

    const handleHowToUseClose = () => {
        setHowToUseOpen(false);
    };

    return (

        <div>
            <Button onClick={handleHowToUseOpen("paper")}>(How to Use)</Button>

            <Dialog
                //hideBackdrop
                disableEnforceFocus
                open={howToUseOpen}

                style={{position: "fixed"}}

                onClose={() => {
                }}
                //scroll='paper'
                PaperComponent={PaperComponent}
                aria-labelledby="scroll-dialog-title"
                disableBackdropClick  // Clicks outside the Dialog do not close it

            >
                <DialogTitle id="scroll-dialog-title">How to Use Voice Translation</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>

                    <DialogContentText sx={{ color: 'red' }}>
                        The Voice Translation is used to capture the system audio from your computer's speaker, rather than microphone.
                        When used in an online meeting, the other person's voice will be translated, but your own voice will not be translated.
                        <br></br>
                        <br></br>
                        Currently, the "Voice Translation" is only supported on Windows system with Chrome or Microsoft Edge.
                        Make sure you are using one of these two web browsers if you want to try this feature.
                    </DialogContentText>
                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 1: In the AIT Portal page, select the “From” and “To” language as you want.
                        Currently, we don’t support automatic language detection.

                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step1}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 2: Click the button “Start Voice Translation” shown below:

                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step2}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 3: In the pop-out window, choose “Entire Screen” shown in the picture:
                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step3}
                        alt="image"
                    />


                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 4: Click the screen in the middle with “Entire screen”.
                    </DialogContentText>
                    <DialogContentText sx={{ color: 'red' }}>
                        This step is very important and necessary.
                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step4}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 5: Open the “Also share system audio button”.
                    </DialogContentText>
                    <DialogContentText sx={{ color: 'red' }}>
                        This step is very important and necessary.
                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step5}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 6: Click the “Share” button on the window

                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step6}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Now the AIT Portal can get the system audio and do the translation.
                    </DialogContentText>
                    <DialogContentText sx={{ color: 'green' }}>
                        The "To" Text Field is movable when the voice translation is on.
                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step7}
                        alt="image"
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHowToUseClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}


export default HowtoUseDialog;