/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "e7eef7a3ab804f42a3b846d0abed023f",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "e7eef7a3ab804f42a3b846d0abed023f",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "translatorAPI",
            "endpoint": "https://wsh0fiz53g.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:e5f081fc-219c-444c-8c55-35c76aacd521",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MTUm6WyW1",
    "aws_user_pools_web_client_id": "46h7va2hf08vpk4rk94rutbv52",
    "oauth": {
        "domain": "translator.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "translator-storage-3f710c1242513-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
