

import step2 from "../pics/how-to/step2_mac.png";
import step3 from "../pics/how-to/step3_mac.png";
import step4 from "../pics/how-to/step4_mac.png";
import step5 from "../pics/how-to/step5_mac.png";
import step6 from "../pics/how-to/step6_mac.png";
import step7 from "../pics/how-to/step7_mac.png";
import step8 from "../pics/how-to/step8_mac.png";
import step9 from "../pics/how-to/step7.png";
import brewpic from "../pics/how-to/brew.png"
import unselectpic from "../pics/how-to/unselect.png"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";


export function HowtoUseDialogMacOS () {

    const [howToUseOpen, setHowToUseOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    function PaperComponent(props) {
        return (
            <Draggable disabled={true}>
                <Paper {...props} />
            </Draggable>
        )
    }

    const handleHowToUseOpen = (scrollType) => () => {
        setHowToUseOpen(true);
        setScroll(scrollType)
    }

    const handleHowToUseClose = () => {
        setHowToUseOpen(false);
    };

    return (

        <div>
            <Button onClick={handleHowToUseOpen("paper")}>(How to Use)</Button>

            <Dialog
                //hideBackdrop
                disableEnforceFocus
                open={howToUseOpen}

                style={{position: "fixed"}}

                onClose={() => {
                }}
                //scroll='paper'
                PaperComponent={PaperComponent}
                aria-labelledby="scroll-dialog-title"
                disableBackdropClick  // Clicks outside the Dialog do not close it

            >
                <DialogTitle id="scroll-dialog-title">How to Use Voice Translation</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText sx={{color: 'black'}}>
                        Welcome to use voice translation!
                    </DialogContentText>
                    <DialogContentText sx={{color: 'red'}}>
                        <br></br>
                        The Voice Translation is used to capture the system audio from your computer's speaker, rather
                        than microphone.
                        When used in an online meeting, the other person's voice will be translated, but your own voice
                        will not be translated.
                        <br></br>
                        <br></br>
                        Currently, the "Voice Translation" is only supported on MacOS system with Chrome or Microsoft
                        Edge.
                        Make sure you are using one of these two web browsers if you want to try this feature.
                    </DialogContentText>
                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 1: Install the BlackHole in Mac OS first. Run below command in the terminal:
                        <br></br>
                        brew install blackhole-2ch
                        <br></br>
                        <br></br>
                        Note: You can open the terminal as below:
                        <br></br>
                        In the Finder , open the /Applications/Utilities folder, then double-click Terminal.
                        <br></br>
                        <br></br>
                        If brew is not installed on your system. Please run below command first in the terminal to
                        install it first.
                        <br></br>
                        /bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/HEAD/install.sh)"

                    </DialogContentText>
                    <DialogContentText sx={{color: 'red'}}>
                        <br></br>
                        After installing the brew, it requires below steps to add brew command into your path. So don’t
                        skip the “Next steps” posted in the terminal:
                    </DialogContentText>

                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={brewpic}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 2: Open Audio MIDI Setup from Spotlight or from /Applications/Utilities/Audio Midi
                        Setup.app.

                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step2}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Then you can use brew to install BlackHole in your computer.
                        <br></br>
                        Step 3: Click the '+' icon at the lower left corner and select 'Create Multi-Output Device'.
                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step3}
                        alt="image"
                    />


                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 4: Add your speakers and “BlackHole 2ch” to the multi-output device. Then set the “Sample
                        Rate” to 44.1kHz.
                        If you want to use head phone, please also add it into this group before using voice
                        translation.
                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step4}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 5: Select this multi-output device and right click on it. Choose “Use This Device For Sound
                        Output”. It uses your speaker (application or system-wide) to play audio into BlackHole.
                    </DialogContentText>

                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step5}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        If you have multiply speakers in your computer, for example, built-in speaker of computer,
                        speaker of monitor and your head phone.
                        The voice may come out from these speakers at the same time. You can select or un-select them as
                        you need to use before the meeting.
                        Please set this up before the meeting. And please make sure “BlackHole 2ch” is selected all the
                        time.

                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={unselectpic}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 6: In the AIT Portal page, select the “From” and “To” language as you want. Currently,
                        “Auto” is not supported.

                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step6}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 7: Click the button “Start Voice Translation” shown below:
                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step7}
                        alt="image"
                    />
                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Step 8: Please allow the AIT Portal to access the microphone in the popup dialog box after
                        clicking the “Start Voice Translation” button.
                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step8}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        After this, if there are some audio to the system speaker, the AIT Portal can get the system
                        audio and do the translation.
                    </DialogContentText>


                    <DialogContentText sx={{color: 'green'}}>
                        The "To" Text Field is movable when the voice translation is on.
                    </DialogContentText>
                    <img
                        style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}
                        src={step9}
                        alt="image"
                    />

                    <DialogContentText sx={{color: 'black'}}>
                        <br></br>
                        Limitation: In Mac OS, the voice from same type of web browser with AIT portal can't be gotten.
                        If AIT portal is opened with Chrome,
                        the audio being played also with Chrome can't be gotten by AIT Portal.
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHowToUseClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}


export default HowtoUseDialogMacOS;