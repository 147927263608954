

export const allowedList = [
    "songchao",
    "bingozh",
    "katsua",
    "bakariw",
    "shinkom",
    "jimboyj",
    "jungeric",
    "kenikono",
    "yingan",
    "shinozta",
    "yuminasu",
    "hirof",
    "mfurut",
    "shkosugi",
    "royamada",
    "yamkyok",
    "damanimd",
    "bhaankur",
    "kawtakao",
    "takesuzu",
    "yuton",
    "broughtc",
    "chrhuan",
    "aizhao",
    "syamano",
    "tankan",
    "yanglun",
    "emihagi",
    "houmanti",
    "ahnaabid",
    "qiuyunz",
    "yifeizh",
    "wangful",
    "wanlei",
    "skyliaoo",
    "donglonz",
    "junyrbai",
    "manlei",
    "ppengsu",
    "qiyingz",
    "wihu",
    "dyechua",
    "yyinw",
    "yancyso",
    "cordelle",
    "ceciliau",
    "wunnavam",
    "urmisako",
    "nishinao",
    "sarapike",
    "asukt",
    "hssugie",
    "cmorishi",
    "sonomik",
    "hidehikf",
    "skuwahar",
    "ydaisu",
    "kikucchi",
    "namnakan",
    "mfurut",
    "ynaoyuki",
    "ntakatos",
    "jueohara",
    "rubyccha",
    "cmorishi",
    "sonomik",
    "hidehikf",
    "skuwahar",
    "ydaisu",
    "kikucchi",
    "namnakan",
    "mfurut",
    "woojinoh",
    "yuast",
    "keinaga",
    "yagihito",
    "tschmak",
    "okatakah",
    "tanoaya",
    "knio",
    "hirof",
    "miyazsat",
    "yutast",
    "hjaehun",
    "tktkfumi",
    "knariga",
    "suzumitf",
    "pyuji",
    "marikoma",
    "miasaba",
    "yuytakah",
    "hiroro",
    "hspatric",
    "akkurasa"

]