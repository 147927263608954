import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Amplify, Analytics, API} from 'aws-amplify';
// import {Analytics} from '@aws-amplify/analytics';
// import {API} from '@aws-amplify/api';
import { CookiesProvider } from 'react-cookie';

const stack = require('./aws-exports.js').default;

const callbackUrl = window.location.origin;
const config = {
    oauth: {
        domain: stack.oauth.domain,
        scope: ['openid'],
        redirectSignIn: callbackUrl,
        redirectSignOut: callbackUrl,
        responseType: 'code'
    },
    // Cognito
    aws_cognito_identity_pool_id: stack.aws_cognito_identity_pool_id,
    aws_cognito_region: stack.aws_cognito_region,
    aws_user_pools_id: stack.aws_user_pools_id,
    aws_user_pools_web_client_id: stack.aws_user_pools_web_client_id,
    aws_user_files_s3_bucket: stack.aws_user_files_s3_bucket,
    aws_user_files_s3_bucket_region: stack.aws_user_files_s3_bucket_region,

    aws_project_region: stack.aws_project_region,
}

Amplify.configure(config)

const analyticsConfig = {
  AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: 'e7eef7a3ab804f42a3b846d0abed023f',
        // Amazon service region
        region: 'us-east-1',
        mandatorySignIn: false,
  }
}

Analytics.configure(analyticsConfig)

const apiConfig = {
  API: {
    endpoints: [
        {
            name: "translatorAPI",
            endpoint: "https://wsh0fiz53g.execute-api.us-east-1.amazonaws.com/staging",
            region: 'us-east-1'
        },
        {
            name: 'aitportalAPI',
            endpoint: 'https://api.sli.translator.amazon.dev',
            region: 'us-east-1',
        }
    ]
}
}
API.configure(apiConfig);

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
